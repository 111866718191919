/* eslint-disable */ 
import React, { useEffect, useState } from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import TokenMorpher from "views/Token/Components/TokenMorpher"
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { getUserSession } from "services/UserManagement";
import { getTokenById, getTokenBalanceFromStellar } from "services/TokenManagement";
// import Paginations from "components/Pagination/Pagination.js";
import Pagination from '@material-ui/lab/Pagination';
import Grid from '@material-ui/core/Grid';
import DummyToken from "views/Token/Components/DummyToken"

const useStyles = makeStyles(styles);

export default function Token(props) {
  const classes = useStyles();
  const { id } = props.match.params

  const [user, setUser] = useState(getUserSession())
  const [token, setToken] = useState(null)
  const [loading, setloading] = useState(false)
  const [loadingText, setLoadingText] = useState("loading")
  const [placeholder, setPlaceholder] = useState([0])
  useEffect(() => {
    const fetchData = async () => {
      setloading(true)
      setLoadingText("Verifying Token In Stellar")
      const balances = await getTokenBalanceFromStellar(user.publicKey)
      setLoadingText("Retrieving MetaData From Niftron")
      let result = await getTokenById(id)
      if (result != null) {
        if (result.data) {

          if (balances != null) {
            balances.forEach((sToken) => {
              if (sToken.assetCode == result.data.assetCode) {
                result.data.assetCount = sToken.balance * 10000000
              }
            })
          }

          setToken(result.data)
        }
        setloading(false)
      }

      // setToken({ "_id": "NN013DEE8486", "tokenName": "The High Queen", "tokenType": "NFT", "assetRealm": "Digital", "tradable": false, "category": "DigitalArt", "assetCode": "NN013DEE8486", "assetIssuer": "GA6T6MHVZBAS6DNFADWJ62RBREUQJQWUVXQMNCYKGAUNYWFIHK7ZUGUV", "assetCount": 1, "previewUrl": "https://niftron-media.s3.us-east-1.amazonaws.com/8aa0a9a6-b9da-4995-95b7-888ce4da4303", "ipfsHash": "QmYvehfWkNuiWJ1rTRnijpJ5S2eNzPHgaFqsWyCFuGLZf9", "price": 0, "xdr": "AAAAAD0/MPXIQS8NpQDsn2ohiSkEwtSt4MaLCjAo3FioOr+aAAAAyAAGkJYAAAADAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgAAAAEAAAAAV5XPUr5nULgs5wUiDAVL+4lg2WMwbthfuDJtSg5IZQ0AAAAGAAAAAk5OMDEzREVFODQ4NgAAAAA9PzD1yEEvDaUA7J9qIYkpBMLUreDGiwowKNxYqDq/mgAAAAAAmJaAAAAAAQAAAAA9PzD1yEEvDaUA7J9qIYkpBMLUreDGiwowKNxYqDq/mgAAAAEAAAAAV5XPUr5nULgs5wUiDAVL+4lg2WMwbthfuDJtSg5IZQ0AAAACTk4wMTNERUU4NDg2AAAAAD0/MPXIQS8NpQDsn2ohiSkEwtSt4MaLCjAo3FioOr+aAAAAAAAAAAEAAAAAAAAAAQ5IZQ0AAABAW2qY3OUmiNFkhsmAgyY/gbqGLpI3O65n/J26qLYwHtH0o6GgbqDY8ELbDrxYZY4lw+AIKabBB3EotjrQtaJ3Cw==", "createdAt": "2020-05-26T13:25:38.174Z" })
      // setloading(false)

    }
    fetchData()
  }, []);
  return (
    <div>
      {loading &&
        <DummyToken loadingText={loadingText} />
      }
      {!loading && token &&
        <TokenMorpher item={token} />
      }
    </div>
  );
}
