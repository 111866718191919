/* eslint-disable */ 
import React, { useState } from "react";
import 'react-image-crop/dist/ReactCrop.css';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import watermark from "watermarkjs";
import sha256 from "sha256";

// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

import CardFooter from "components/Card/CardFooter";
import InputAdornment from "@material-ui/core/InputAdornment";
import Delete from '@material-ui/icons/Delete';
// import PublicIcon from '@material-ui/icons/Public';
// import CustomInputSelect from "components/CustomInputSelect/CustomInputSelect";
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Dropzone from 'react-dropzone';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Link } from "react-router-dom";
import { getUserSession, decryptSecret } from "services/UserManagement"
import { addBadge } from "services/TokenManagement"
import DataInputFieldGenerator from "components/DataInputFieldGenerator/DataInputFieldGenerator";
import { BuildBadgeNFTXDR, signXDR } from "services/XDRBuilder"
import { BuildNiftronId } from "services/NiftronBuilder"
import Icon from "@material-ui/core/Icon";

// import imageCompression from 'browser-image-compression';
import IPFS from 'ipfs';
import { useSnackbar } from 'notistack';

import styles from "assets/jss/material-kit-react/views/loginPage";
import { Keypair } from "stellar-sdk";
import Paper from '@material-ui/core/Paper';
import CardToken from "components/Card/CardToken.js";
import { CardMedia } from '@material-ui/core';


const useStyles2 = makeStyles(styles);
const useStyles = makeStyles((theme) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paperName: {
    padding: theme.spacing(1),
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
  },
  paperValue: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


export default function MintBadgeToken(props) {
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const classes2 = useStyles2();

  const [user, setUser] = useState(getUserSession())
  const [activeStep, setActiveStep] = React.useState(0);

  //basic details
  const [tokenName, setTokenName] = useState("");
  const [tokenType, setTokenType] = useState("SFT");
  const [assetRealm, setAssetRealm] = useState(props.realm);
  const [tradable, setTradable] = useState(false);
  const [category, setCategory] = useState(props.category);
  //basic details error
  const [tokenNameError, setTokenNameError] = useState(false);
  const [tokenTypeError, setTokenTypeError] = useState(false);
  const [assetRealmError, setAssetRealmError] = useState(false);
  const [tradableError, setTradableError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);


  //token specific details
  //d-art
  const [file, setFile] = useState(null)
  const [fileWM, setFileWM] = useState(null)
  const [price, setPrice] = useState(0);
  const [assetCount, setAssetCount] = useState(1);

  //d-art error
  const [fileError, setFileError] = useState(false)
  const [fileWMError, setFileWMError] = useState(false)
  const [priceError, setPriceError] = useState(false);
  const [assetCountError, setAssetCountError] = useState(false);


  //Confirm 
  //d-art
  const [secret, setSecret] = useState("");
  //d-art error
  const [secretError, setSecretKeyError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingMessage, setloadingMessage] = useState("please remain patient");

  const [hoverPassword, setHoverPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [linkStyle, setLinkStyle] = useState({ cursor: 'pointer' });

  const [open, setOpen] = React.useState(false);


  const [fieldContentArray, setFieldContentArray] = useState([])
  const [fieldContent, setFieldContent] = useState({})
  const [showInput, setShowInput] = useState(false)

  const getSteps = () => {
    return ['Basic Details', 'Badge Details'];
  }

  const steps = getSteps();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      props.setStep("CATEGORY")
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };


  const Basic = (event) => {
    event.preventDefault()
    //required check
    setTokenNameError(tokenName === "" ? true : false);
    setTokenTypeError(tokenType === "" ? true : false);
    setAssetRealmError(assetRealm === "" ? true : false);
    setCategoryError(category === "" ? true : false);
    if (tokenName != "" && tokenType != "" && category != "" && assetRealm != "") {
      handleNext()
    }
  };

  const SpecificArt = (event) => {
    event.preventDefault()
    //required check
    setFileError(file === null ? true : false);
    setFileWMError(fileWM === null ? true : false);
    setPriceError(price < 0 && tradable ? true : false);
    setAssetCountError(!assetCount || assetCount < 1 ? true : false);

    if (file != null && fileWM != null && price > -1) {
      handleNext()
    }
  };

  const AddToIPFS = async () => {
    const stringToUse = JSON.stringify(fieldContent)
    const node = await IPFS.create({ repo: String(Math.random() + Date.now()) })
    //console.log('IPFS node is ready')
    const { id, agentVersion, protocolVersion } = await node.id()
    //console.log(id, agentVersion, protocolVersion)
    for await (const { cid } of node.add(stringToUse)) {
      return cid.toString()
    }
    return null
  }
  const submitDart = async (e) => {
    e.preventDefault()

    setLoading(true)
    setOpen(false);

    //console.log(user)
    setloadingMessage("Verifying User Credentials ...")
    let secretKey = "";
    if (user.authType !== "2") {
      secretKey = decryptSecret(user.encryptedSecret, sha256(secret))
    }
    if (user.authType === "2") {
      secretKey = secret.toUpperCase()
    }

    if (!secretKey || secretKey == "" || secretKey == null || secretKey == undefined) {
      enqueueSnackbar("Invalid Credential", { variant: "warning" });
    } else {
      setloadingMessage("Uploading Data To IPFS ...")

      const IPFSHash = await AddToIPFS()

      setloadingMessage("Building Blockchain XDR ...")
      const creatorKeypair = Keypair.fromSecret(secretKey);
      let IssuerPublicKey = user.publicKey
      if (!tradable) {
        user.accounts.forEach((account) => {
          if (account.accountType === "1") {
            IssuerPublicKey = account.publicKey
          }
        })
      }
      const { xdrs, niftronId } = await BuildBadgeNFTXDR(
        tokenName,
        tokenType,
        tradable,
        true,
        false,
        creatorKeypair,
        assetCount, sha256(JSON.stringify(fieldContent)))

      let xdr;
      if (xdrs != null && xdrs.length > 0) {
        await Promise.all(
          xdrs.map(async (item, index, array) => {
            xdrs[index].xdr = await signXDR(item.xdr, creatorKeypair.secret());
          })
        );
        xdr = xdrs[0]?.xdr
      }
      //console.log(IPFSHash)

      setloadingMessage("Storing Meta Data to Niftron ...")

      const serverRes = await addBadge({
        tokenName: tokenName,
        tokenType: tokenType,
        assetRealm: assetRealm,
        tradable: tradable,
        transferable: false,
        category: category,
        assetCode: niftronId,
        assetIssuer: IssuerPublicKey,
        issuerAlias: user.alias,
        assetCount: assetCount,
        previewUrl: fileWM,
        ipfsHash: IPFSHash,
        price: price,
        xdr
      })
      switch (serverRes) {
        case 200: enqueueSnackbar("Token Minted Successfully", { variant: "success" });
          props.setStep("COMPLETED"); break;
        case 201: enqueueSnackbar("Account Not Found", { variant: "warning" }); break;
        case 204: enqueueSnackbar("Token Name is already taken", { variant: "warning" }); break;
        case 202: enqueueSnackbar("Insufficient Fund in Account", { variant: "warning" }); break;
        case 400: enqueueSnackbar("Token Minting failed", { variant: "error" }); break;
        case null: enqueueSnackbar("Token Minting failed", { variant: "error" }); break;
        default: enqueueSnackbar("Token Minting failed", { variant: "error" });
      }
    }
    setLoading(false)
  }

  const addData = (fieldName, fieldValue) => {
    let data = fieldContent
    data[fieldName] = fieldValue
    setFieldContent(data)

    var array = []
    for (var property in data) {
      if (data.hasOwnProperty(property)) {
        array.push({ name: property, value: data[property] })
      }
    }
    setFieldContentArray(array)
  }

  const removeData = (fieldName) => {
    let data = fieldContent
    delete data[fieldName]
    setFieldContent(data)
    var array = []
    for (var property in data) {
      if (data.hasOwnProperty(property)) {
        array.push({ name: property, value: data[property] })
      }
    }
    setFieldContentArray(array)
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {activeStep === steps.length ? (
                <div>
                  <Typography className={classes.instructions}>Confirm Your Badge Details</Typography>
                  <Dialog
                    maxWidth="sm"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    scroll={"body"}
                  >
                    <DialogTitle id="responsive-dialog-title">{"Please Confirm Action"}</DialogTitle>
                    <DialogContent dividers>
                      <form style={loading ? {
                        filter: "blur(1px)",
                        "-webkit-filter": "blur(1px)"
                      } : null} onSubmit={submitDart}>
                        <CustomInput
                          error={secretError}
                          labelText={user.authType === "2" ? "Secret Key *" : "Password *"}
                          id="tokenName"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: !showPassword ? "password" : "text",
                            endAdornment: (
                              <InputAdornment position="end">
                                {hoverPassword &&
                                  <Icon style={linkStyle} onMouseEnter={() => { setHoverPassword(true) }} onMouseLeave={() => { setHoverPassword(false) }}
                                    className={classes.inputIconsColor} onClick={() => {
                                      setShowPassword(!showPassword)
                                    }}>
                                    {showPassword ? "visibilityoff" : "visibility"}</Icon>
                                }
                                {!hoverPassword &&
                                  <Icon style={linkStyle} className={classes.inputIconsColor}
                                    onMouseEnter={() => { setHoverPassword(true) }} onMouseLeave={() => { setHoverPassword(false) }}>
                                    lock</Icon>
                                }
                              </InputAdornment>
                            ), required: true,
                            onChange: function (e) {
                              setSecret(e.target.value)
                              setSecretKeyError(e.target.value === "" ? true : false)
                            }, value: secret
                          }}
                        />
                        {user.authType === "2" && <>
                          <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center"
                          >

                            <Grid item xs={3}>
                              or                  </Grid>

                          </Grid>

                          <Dropzone onDrop={acceptedFiles => {
                            const reader = new FileReader()
                            reader.addEventListener("load", async () => {
                              // setFile(reader.result)
                              setSecret(JSON.parse(atob(reader.result)).secretKey)
                            });
                            reader.readAsText(acceptedFiles[0])
                          }}
                            multiple={false} accept={[".niftron"]}>
                            {({ getRootProps, getInputProps }) => (
                              <Link >
                                <section className={"container"}>
                                  <div {...getRootProps({ className: fileError ? 'dropzone2Error' : 'dropzone2' })}>
                                    <input {...getInputProps()} />
                                    <p>Drop or select your niftron credential file</p>

                                  </div>
                                </section>
                              </Link>
                            )}
                          </Dropzone></>
                        }
                        <Grid container className={classes.root} spacing={2}>
                          <Grid item xs={12}>
                            <Grid container justify="center" spacing={2}>
                              <Grid item>
                                <Button color={"info"} onClick={submitDart}
                                  type={"submit"} disabled={loading}>Confirm</Button>
                              </Grid>

                            </Grid>
                          </Grid>
                        </Grid>
                                  </form>
                    </DialogContent>
                  </Dialog>
                  <Card>
                    <CardBody>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={4}>


                          <Card>
                            <CardToken color="grey">
                              <CardMedia style={{ height: 345, maxHeight: 345 }} image={fileWM} />
                            </CardToken>
                          </Card>

                          {/* <img src={fileWM} style={{ height: "200px", marginLeft: "20px", marginRight: "20px" }}
                            className={classes2.imgRounded + " " + classes2.imgFluid} alt={"Market Preview"} /> */}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={8}>
                          <div className={classes.root}>
                            <Grid container spacing={1}>
                              <Grid container item xs={12} spacing={3}>
                                <Grid item xs={4}>
                                  <Paper className={classes.paperName}> Niftron Id:</Paper>
                                </Grid>
                                <Grid item xs={8}>
                                  <Paper className={classes.paperValue}> {BuildNiftronId(
                                    tokenName, tokenType, tradable, false,
                                    false, sha256(JSON.stringify(fieldContent))).id}</Paper>
                                </Grid>
                              </Grid>
                              <Grid container item xs={12} spacing={3}>
                                <Grid item xs={4}>
                                  <Paper className={classes.paperName}> Badge Name:</Paper>
                                </Grid>
                                <Grid item xs={8}>
                                  <Paper className={classes.paperValue}> {tokenName}</Paper>
                                </Grid>
                              </Grid>

                              <Grid container item xs={12} spacing={3}>
                                <Grid item xs={4}>
                                  <Paper className={classes.paperName}> Token Type:</Paper>
                                </Grid>
                                <Grid item xs={8}>
                                  <Paper className={classes.paperValue}>  {tokenType}</Paper>
                                </Grid>
                              </Grid>

                              <Grid container item xs={12} spacing={3}>
                                <Grid item xs={4}>
                                  <Paper className={classes.paperName}> Asset Realm:
                                  </Paper>
                                </Grid>
                                <Grid item xs={8}>
                                  <Paper className={classes.paperValue}> {assetRealm}</Paper>
                                </Grid>
                              </Grid>

                              <Grid container item xs={12} spacing={3}>
                                <Grid item xs={4}>
                                  <Paper className={classes.paperName}> Category:</Paper>
                                </Grid>
                                <Grid item xs={8}>
                                  <Paper className={classes.paperValue}> {category}</Paper>
                                </Grid>
                              </Grid>

                              <Grid container item xs={12} spacing={3}>
                                <Grid item xs={4}>
                                  <Paper className={classes.paperName}> Tradable:</Paper>
                                </Grid>
                                <Grid item xs={8}>
                                  <Paper className={classes.paperValue}> {tradable ? "Yes" : "No"}</Paper>
                                </Grid>
                              </Grid>

                              <Grid container item xs={12} spacing={3}>
                                <Grid item xs={4}>
                                  <Paper className={classes.paperName}> Price:{" "}</Paper>
                                </Grid>
                                <Grid item xs={8}>
                                  <Paper className={classes.paperValue}> ${price}</Paper>
                                </Grid>
                              </Grid>

                              <Grid container item xs={12} spacing={3}>
                                <Grid item xs={4}>
                                  <Paper className={classes.paperName}>  No of Badges:</Paper>
                                </Grid>
                                <Grid item xs={8}>
                                  <Paper className={classes.paperValue}> {assetCount}</Paper>
                                </Grid>
                              </Grid>

                              <Grid container item xs={12} spacing={3}>
                                <Grid item xs={4}>
                                  <Paper className={classes.paperName}>  Data:</Paper>
                                </Grid>
                                <Grid item xs={8}>
                                  <Paper className={classes.paperValue}> {(Object.keys(fieldContent)).length > 0 ? JSON.stringify(fieldContent) : "NA"}</Paper>
                                </Grid>
                              </Grid>

                            </Grid>
                          </div>
                          <Typography variant="subtitle1">
                            {loading && loadingMessage}
                            {loading && <CircularProgress />}
                          </Typography>

                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                  <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                      <Grid container justify="center" spacing={2}>
                        <Grid item>
                          <Button disabled={loading} onClick={handleReset}>Make a Change</Button>

                        </Grid>
                        <Grid item>
                          <Button disabled={loading} color={"info"} onClick={handleOpen}>{tradable ? "Create and Publish" : "Create"}</Button>

                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Button disabled={loading} onClick={handleReset}>Make a Change</Button>
                  <Button disabled={loading} color={"info"} onClick={handleOpen}>{tradable ? "Create and Publish" : "Create"}</Button> */}
                </div>
              ) : (
                  <div>
                    <Typography className={classes.instructions}>
                      {activeStep === 0 &&
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={12}>
                            <Card >
                              <form className={classes2.form} style={loading ? {
                                filter: "blur(1px)",
                                "-webkit-filter": "blur(1px)"
                              } : null} onSubmit={Basic}>
                                <CardHeader color="info" className={classes2.cardHeader}>
                                  <h3>Basic Details</h3>
                                </CardHeader>
                                <p className={classes2.divider}>What would you like to call your Badge?</p>
                                <CardBody>
                                  <GridContainer justify="center">
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                      <CustomInput
                                        error={tokenNameError}
                                        labelText="Badge Name *"
                                        id="tokenName"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          type: "text",
                                          // endAdornment: (
                                          //   <InputAdornment position="end">
                                          //     <PermIdentityIcon className={classes2.inputIconsColor} />
                                          //   </InputAdornment>
                                          // ), 
                                          required: true,
                                          onChange: function (e) {
                                            setTokenName(e.target.value)
                                            setTokenNameError(e.target.value === "" ? true : false)
                                          }, value: tokenName
                                        }}
                                      />
                                    </GridItem>
                                  </GridContainer>
                                  {/* <GridContainer>
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                      <CustomInputSelect
                                        error={tokenTypeError}
                                        options={<Select disabled
                                          value={tokenType}
                                          onChange={(e) => {
                                            setTokenType(e.target.value)
                                            setTokenTypeError(e.target.value === "" ? true : false)
                                            if (e.target.value === "NFT") {
                                              setAssetCount(1)
                                            }
                                          }}
                                          displayEmpty
                                          className={classes2.selectEmpty}>
                                          <MenuItem value="">
                                            <em>Choose Your Token Type</em>
                                          </MenuItem>
                                          <MenuItem value={"NFT"}>Non-Fungible Token</MenuItem>
                                          <MenuItem value={"SFT"}>Semi-Fungible Token</MenuItem>
                                        </Select>}
                                        labelText="Token Type *"
                                        id="tokenType"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          type: "text",
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <PublicIcon className={classes2.inputIconsColor} />
                                            </InputAdornment>
                                          ), required: true
                                        }} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                      <CustomInputSelect

                                        error={assetRealmError}
                                        options={<Select
                                          disabled value={assetRealm}
                                          onChange={(e) => {
                                            setAssetRealm(e.target.value)
                                            setAssetRealmError(e.target.value === "" ? true : false)
                                          }}
                                          displayEmpty
                                          className={classes2.selectEmpty}>
                                          <MenuItem value="">
                                            <em>Choose Your Asset Realm</em>
                                          </MenuItem>
                                          <MenuItem value={"DIGITAL"}>Digital Realm</MenuItem>
                                          <MenuItem value={"PHYSICAL"} disabled>Physical Realm</MenuItem>
                                        </Select>}
                                        labelText="Asset Realm *"
                                        iD="assetRealm"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          type: "text",
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <PublicIcon className={classes2.inputIconsColor} />
                                            </InputAdornment>
                                          ), required: true
                                        }} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                      <CustomInputSelect
                                        //info
                                        error={categoryError}
                                        options={<Select disabled
                                          value={category}
                                          onChange={(e) => {
                                            setCategory(e.target.value)
                                            setCategoryError(e.target.value === "" ? true : false)
                                          }}
                                          displayEmpty
                                          className={classes2.selectEmpty}>
                                          <MenuItem value="">
                                            <em>Choose Your Category</em>
                                          </MenuItem>
                                          {assetRealm === "DIGITAL" &&
                                            <MenuItem value={"DIGITALART"}>Digital Art</MenuItem>
                                          }
                                          {assetRealm === "DIGITAL" &&
                                            <MenuItem value={"TICKET"}>Ticket</MenuItem>
                                          }
                                          {assetRealm === "DIGITAL" &&
                                            <MenuItem value={"DOAMIN"}>Domain Names</MenuItem>
                                          }
                                          {assetRealm === "DIGITAL" &&
                                            <MenuItem value={"CERTIFICATE"}>Certificate</MenuItem>
                                          }                                       </Select>}
                                        labelText="Category *"
                                        id="category"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          type: "text",
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <PublicIcon className={classes2.inputIconsColor} />
                                            </InputAdornment>
                                          ), required: true
                                        }} />
                                    </GridItem>
                                  </GridContainer>
                                  <GridContainer >
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                      <Grid component="label" container alignItems="center" spacing={1}>
                                        <Grid item>Non-tradable</Grid>
                                        <Grid item>
                                          <Switch
                                            disabled
                                            color={"primary"}
                                            checked={tradable}
                                            onChange={(e) => {
                                              setTradable(e.target.checked)
                                            }}
                                            name="Tradable"
                                          />
                                        </Grid>
                                        <Grid item>Tradable</Grid>
                                      </Grid>

                                    </GridItem>
                                  </GridContainer> */}
                                  {loading && <LinearProgress />}
                                </CardBody>
                                <CardFooter>
                                  <Grid container className={classes.root} spacing={2}>
                                    <Grid item xs={12}>
                                      <Grid container justify="center" spacing={2}>
                                        <Grid item>
                                          <Button
                                            // disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className={classes.backButton}
                                          > Back</Button>
                                        </Grid>
                                        <Grid item>
                                          <Button variant="contained" color="info" onClick={Basic} type={"submit"}
                                            disabled={false}>
                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                </CardFooter>
                              </form>
                            </Card>
                          </GridItem>
                        </GridContainer>}
                      {activeStep === 1 && <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={12}>
                          <Card >
                            <form className={classes2.form} style={loading ? {
                              filter: "blur(1px)",
                              "-webkit-filter": "blur(1px)"
                            } : null} onSubmit={SpecificArt}>
                              <CardHeader color="info" className={classes2.cardHeader}>
                                <h3>Badge Details</h3>
                              </CardHeader>
                              {category === "BADGE" &&
                                <CardBody>
                                  <GridContainer justify="center">
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                      <Dropzone onDrop={acceptedFiles => {
                                        const reader = new FileReader()
                                        reader.addEventListener("load", async () => {
                                          setFile(reader.result)
                                          setFileError(false);
                                          setFileWM(reader.result)
                                          setFileWMError(false);

                                          // const options = {
                                          //   maxSizeMB: 0.2,
                                          //   maxWidthOrHeight: 500,
                                          //   useWebWorker: true,
                                          //   fileType: 'png'
                                          // }
                                          // const compressedFile = await imageCompression(acceptedFiles[0], options);
                                          // watermark([compressedFile])
                                          //   // .image(rotate)
                                          //   .image(watermark
                                          //     .text
                                          //     .center(`${user.publicKey.substring(0, 4)}...${user.publicKey.substring(user.publicKey.length - 4)}`,
                                          //       '40px Josefin Slab', '#bbbbbb', 1))
                                          //   // .render()
                                          //   // .image(watermark.image.lowerRight('NIFTRON', '20px Josefin Slab', '#bbbbbb', 1))
                                          //   // .load([image])
                                          //   // .image(watermark.image.lowerRight(0.2))
                                          //   .then(function (img) {
                                          //     setFileWM(img.src)
                                          //     setFileWMError(false);
                                          //   });
                                        });
                                        reader.readAsDataURL(acceptedFiles[0])
                                      }}
                                        multiple={false} accept={[".png", ".gif", ".jpg"]}>
                                        {({ getRootProps, getInputProps }) => (
                                          <Link >
                                            <section className={"container"}>
                                              <div {...getRootProps({ className: fileError ? 'dropzoneError' : 'dropzone' })}>
                                                <input {...getInputProps()} />
                                                <p>Drag 'n' drop your badge art file here, or click to select badge art file</p>
                                                <GridContainer justify="center">
                                                  {/* <GridItem xs={12} sm={12} md={6} lg={6}>
                                                    {file != null && <><p>Original Art</p>
                                                      <img src={file} style={{ height: "200px", marginLeft: "20px", marginRight: "20px" }}
                                                        className={classes2.imgRounded + " " + classes2.imgFluid} alt={"Original Art"} /></>}
                                                  </GridItem> */}
                                                  <GridItem xs={12} sm={12} md={8} lg={8}>
                                                    {fileWM != null && <><p>Badge Art Preview</p>
                                                      <img src={fileWM} style={{ height: "200px", marginLeft: "20px", marginRight: "20px" }}
                                                        className={classes2.imgRounded + " " + classes2.imgFluid} alt={"Badge Art Preview"} /></>}
                                                  </GridItem>
                                                </GridContainer>
                                              </div>
                                            </section>
                                          </Link>
                                        )}
                                      </Dropzone>
                                    </GridItem>

                                    {tokenType === "SFT" &&
                                      <GridItem xs={12} sm={12} md={8} lg={8}>
                                        <CustomInput
                                          error={assetCountError}
                                          labelText="Badge Count *"
                                          id="assetCount"
                                          formControlProps={{
                                            fullWidth: true
                                          }}
                                          inputProps={{
                                            type: "number",
                                            // startAdornment: (
                                            //   <InputAdornment position="start">
                                            //     <AttachMoneyIcon className={classes2.inputIconsColor} />
                                            //   </InputAdornment>
                                            // ), required: true,
                                            onChange: function (e) {
                                              setAssetCount(e.target.value)
                                              setAssetCountError(!e.target.value || e.target.value < 0 ? true : false)
                                            }, value: assetCount, disabled: (tokenType === "NFT" ? true : false)
                                          }}
                                        />
                                      </GridItem>

                                    }
                                  </GridContainer>
                                  <GridContainer justify="center">
                                    {fieldContentArray.map((item, key) => (
                                      <GridItem xs={12} sm={12} md={8} lg={8} key={key} >
                                        <CustomInput
                                          labelText={item.name}
                                          id={item.name}
                                          formControlProps={{
                                            fullWidth: true
                                          }}
                                          inputProps={{
                                            type: "text",
                                            endAdornment: (
                                              <InputAdornment position="end" onClick={() => {
                                                removeData(item.name)
                                              }}>
                                                <Link>  <Delete className={classes2.inputIconsColor} /></Link>

                                              </InputAdornment>
                                            ),
                                            onChange: function (e) {
                                              addData(item.name, e.target.value)
                                            }, value: item.value, disabled: true
                                          }}
                                        />
                                        {/* <Button onClick={() => {
                                            removeData(item.name)
                                          }}>X</Button> */}
                                      </GridItem>
                                    ))}
                                    <GridItem xs={12} sm={12} md={8} lg={8}>

                                      {showInput &&
                                        <DataInputFieldGenerator addData={addData} setShowInput={setShowInput}
                                        />
                                      }
                                      <Grid container className={classes.root} spacing={2}>
                                        <Grid item xs={12}>
                                          <Grid container justify="center" spacing={2}>
                                            <Grid item>
                                              {!showInput && <Button
                                                // disabled={activeStep === 0}
                                                onClick={() => {
                                                  setShowInput(true)
                                                }}
                                                className={classes.backButton}
                                              > Add Custom Data</Button>}
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>

                                    </GridItem>

                                  </GridContainer>
                                  {loading && <LinearProgress />}
                                </CardBody>
                              }
                              <CardFooter>
                                <Grid container className={classes.root} spacing={2}>
                                  <Grid item xs={12}>
                                    <Grid container justify="center" spacing={2}>
                                      <Grid item>
                                        <Button
                                          // disabled={activeStep === 0}
                                          onClick={handleBack}
                                          className={classes.backButton}
                                        > Back</Button>
                                      </Grid>
                                      <Grid item>
                                        <Button variant="contained" color="info" onClick={SpecificArt} type={"submit"}
                                          disabled={false}>
                                          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>



                              </CardFooter>
                            </form>
                          </Card>
                        </GridItem>
                      </GridContainer>
                      }

                    </Typography>
                  </div>
                )}
            </div>
          </div>
        </GridItem>
      </GridContainer>
    </div >
  );
}
