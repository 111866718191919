/* eslint-disable */ 
import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { Link } from "react-router-dom";
import { useSnackbar } from 'notistack';

import Login from "views/AuthPage/Components/Login.js";
import SecretLogin from "views/AuthPage/Components/SecretLogin.js";
import RegisterLow from "views/AuthPage/Components/RegisterLow.js";
import RegisterMedium from "views/AuthPage/Components/RegisterMedium.js";
import RegisterHigh from "views/AuthPage/Components/RegisterHigh.js";
import Privacy from "views/AuthPage/Components/Privacy.js";
import KYC from "views/AuthPage/Components/KYC.js";
import GridContainer from "components/Grid/GridContainer.js";
import BlockchainCredentials from "views/AuthPage/Components/BlockchainCredentials.js";
import Account from "views/AuthPage/Components/Account";
import PasswordReset from "views/AuthPage/Components/PasswordReset";
import { getUserSession } from "services/UserManagement.js";

const useStyles = makeStyles(styles);

function AuthPage(props) {
  const [step, setStep] = useState('LOGIN');
  const [previousRoute, setPreviousRoute] = useState('/');
  const [secretKey, setSecretKey] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [alias, setAlias] = useState("");
  const [type, setType] = useState("");
  const [warning, setWarning] = useState("");


  const classes = useStyles();

  const { ...rest } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (props.location.search && props.location.search != "") {
      //console.log(props.location.search)
      const from = props.location.search.split("=");
      const route = atob(from[1]);
      setPreviousRoute(route);
      // window.location.reload(false);
      if (getUserSession()) {
        closeSnackbar();
        setWarning("Please use a creator account to login")
        // enqueueSnackbar("Please use a creator account to login", { variant: "warning" });
      }
    }
    document.body.style.overflow = "scroll";

  }, []);

  return (
    <div>
      <div
        className={classes.container} >
        {/* <div
        className={classes.pageHeader}
        // style={{
        //   backgroundImage: "url(" + image + ")",
        //   backgroundSize: "fixed",
        //   backgroundPosition: "top center"
        // }}
      > */}
        <GridContainer justify="center" >
          <Link to={"/"} >
            <img height="50px" style={{ margin: "50px 0px 50px 0px" }}
              src="sidebar.png" />
          </Link>
        </GridContainer>
        {step === 'LOGIN' && (
          <Login previousRoute={previousRoute} setStep={setStep} history={props.history} warning={warning} />
        )}
        {step === 'SECRETLOGIN' && (
          <SecretLogin previousRoute={previousRoute} setStep={setStep} history={props.history} warning={warning} />
        )}
        {step === 'ACCOUNT' && (
          <Account setStep={setStep} setType={setType} history={props.history} />
        )}
        {step === 'PRIVACY' && (
          <Privacy setStep={setStep} history={props.history} />
        )}
        {step === 'REGISTERLOW' && (
          <RegisterLow previousRoute={previousRoute} setStep={setStep} type={type} history={props.history}
            setSecretKey={setSecretKey} setPublicKey={setPublicKey} setAlias={setAlias}/>
        )}
        {step === 'REGISTERMEDIUM' && (
          <RegisterMedium previousRoute={previousRoute} setStep={setStep} type={type} history={props.history}
            setSecretKey={setSecretKey} setPublicKey={setPublicKey} setAlias={setAlias}/>
        )}
        {step === 'REGISTERHIGH' && (
          <RegisterHigh previousRoute={previousRoute} setStep={setStep} type={type} history={props.history}
            setSecretKey={setSecretKey} setPublicKey={setPublicKey} setAlias={setAlias}/>
        )}
        {step === 'KYC' && (
          <KYC setStep={setStep} history={props.history} previousRoute={previousRoute} kycAlone={false} />
        )}
        {step === 'BLOCKCHAIN' && (
          <BlockchainCredentials previousRoute={previousRoute} setStep={setStep}
            history={props.history} secretKey={secretKey} publicKey={publicKey} alias={alias} />
        )}
        {step === 'PASSWORDRESET' && (
          <PasswordReset previousRoute={previousRoute} setStep={setStep}
            history={props.history} />
        )}
      </div >
      <Footer
      //  whiteFont 
      />
    </div >
  );
}

export default AuthPage