/* eslint-disable */ 
import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import sha256 from "sha256"
import Grid from '@material-ui/core/Grid';
import Email from "@material-ui/icons/Email";
import Typography from '@material-ui/core/Typography';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dropzone, { useDropzone } from 'react-dropzone';
import { Link } from "react-router-dom";
import { getUserSession, encyrptSecret, decryptSecret } from "services/UserManagement"
import PickUser from "views/Token/Transfer/PickUser"
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSnackbar } from 'notistack';
import { Transfer } from "services/TransferManagement"
import { BuildTransferNFTXDR } from "services/XDRBuilder"
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { Keypair } from "stellar-sdk"

const useStyles = makeStyles(styles);

function TransferComponent(props) {
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    setTimeout(function () {
        setCardAnimation("");
    }, 400);
    const [user, setUser] = useState(getUserSession())

    const [transferType, setTransferType] = useState("0");
    const [sender, setSender] = useState(user.publicKey);
    const [receiver, setReceiver] = useState("");
    const [assetCount, setAssetCount] = useState(props.item.tokenType === "NFT" ? "1" : "");

    const [loading, setLoading] = useState(false);

    //Confirm 
    const [secret, setSecret] = useState("");

    //Confirm error
    const [secretError, SecretKeyError] = useState(false);
    const [fileError, setFileError] = useState(false)

    //hover and show
    const [hoverPassword, setHoverPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [linkStyle, setLinkStyle] = useState({ cursor: 'pointer' });

    //Errors 
    const [receiverError, setReceiverError] = useState(false);

    const [assetCountError, setAssetCountError] = useState(false);
    const [open, setOpen] = React.useState(false);

    const classes = useStyles();

    const { ...rest } = props;
    const { enqueueSnackbar } = useSnackbar();
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    const transferToken = async (event) => {
        event.preventDefault()
        var re = /^\d+$/;

        //required check
        setAssetCountError(assetCount != "" || re.test(assetCount) ? false : true);
        setReceiverError(receiver === "" ? true : false);
        // }
        if (assetCount != ""
            && receiver != "") {
            setLoading(true)

            let secretKey = secret
            if (user.authType != "2") {
                if (decryptSecret(user.encryptedSecret, secret) != null) {
                    secretKey = decryptSecret(user.encryptedSecret, sha256(secret))
                }
            }
            const keypair = Keypair.fromSecret(secretKey);
            const xdr = await BuildTransferNFTXDR(
                user.publicKey,
                receiver,
                props.item.assetIssuer,
                props.item.assetCode,
                assetCount,
                // approvers,
                // minTime,
                // maxTime
            )
            if (xdr === null) {
                setLoading(false)
                return
            }
            const response = await Transfer({
                transferType: transferType,
                sender: sender,
                receiver: receiver,
                assetCode: props.item.assetCode,
                assetIssuer: props.item.assetIssuer,
                assetCount: assetCount,
                tokenName: props.item.tokenName,
                previewUrl: props.item.previewUrl,
                xdr: xdr,
            })
            if (response != null) {
                switch (response) {
                    case 200: enqueueSnackbar("Logged In Successfully", { variant: "success" });
                        props.history.push(props.previousRoute); break;
                    case 201: enqueueSnackbar("Account Not Found", { variant: "warning" }); break;
                    case 202: enqueueSnackbar("Password Is Incorrect", { variant: "error" }); break;
                    case 203: enqueueSnackbar("Your are a High Privacy User, Try Secret Key Login", { variant: "warning" }); break;

                    case null: enqueueSnackbar("Login Failed", { variant: "error" });

                }
            }

            setLoading(false)
        }

    };

    return (

        <div className={classes.container}>

            <Dialog
                maxWidth="sm"
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                scroll={"body"}
            >
                <DialogTitle id="responsive-dialog-title">{"Please Confirm Action"}</DialogTitle>
                <DialogContent dividers>
                    <form style={loading ? {
                        filter: "blur(1px)",
                        "-webkit-filter": "blur(1px)"
                    } : null} onSubmit={transferToken}>
                        <CustomInput
                            error={secretError}
                            labelText={user.authType === "2" ? "Secret Key *" : "Password *"}
                            id="tokenName"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: !showPassword ? "password" : "text",
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {hoverPassword &&
                                            <Icon style={linkStyle} onMouseEnter={() => { setHoverPassword(true) }} onMouseLeave={() => { setHoverPassword(false) }}
                                                className={classes.inputIconsColor} onClick={() => {
                                                    setShowPassword(!showPassword)

                                                }}>
                                                {showPassword ? "visibilityoff" : "visibility"}</Icon>
                                        }

                                        {!hoverPassword &&
                                            <Icon style={linkStyle} className={classes.inputIconsColor}
                                                onMouseEnter={() => { setHoverPassword(true) }} onMouseLeave={() => { setHoverPassword(false) }}>
                                                lock</Icon>
                                        }
                                    </InputAdornment>
                                ), required: true,
                                onChange: function (e) {
                                    setSecret(e.target.value)
                                    SecretKeyError(e.target.value === "" ? true : false)
                                }, value: secret
                            }}
                        />
                        {user.authType === "2" && <>
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justify="center"
                            >

                                <Grid item xs={3}>
                                    or                  </Grid>

                            </Grid>

                            <Dropzone onDrop={acceptedFiles => {
                                const reader = new FileReader()
                                reader.addEventListener("load", async () => {
                                    // setFile(reader.result)
                                    setSecret(JSON.parse(atob(reader.result)).secretKey)
                                });
                                reader.readAsText(acceptedFiles[0])
                            }}
                                multiple={false} accept={[".niftron"]}>
                                {({ getRootProps, getInputProps }) => (
                                    <Link >
                                        <section className={"container"}>
                                            <div {...getRootProps({ className: fileError ? 'dropzone2Error' : 'dropzone2' })}>
                                                <input {...getInputProps()} />
                                                <p>Drop or select your niftron credential file</p>

                                            </div>
                                        </section>
                                    </Link>
                                )}
                            </Dropzone></>
                        }
                        <Button color={"info"} onClick={transferToken}
                            type={"submit"} disabled={loading}>Confirm</Button>
                    </form>
                </DialogContent>
            </Dialog>
            <Card >
                <form className={classes.form} onSubmit={(e) => {
                    e.preventDefault()
                    if (true) {
                        handleOpen()
                    }

                }} style={loading ? {
                    filter: "blur(1px)",
                    "-webkit-filter": "blur(1px)"
                } : null}>
                    <CardHeader color="info" className={classes.cardHeader}>
                        <h3>Transfer</h3>
                    </CardHeader>
                    <CardBody>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12}>
                                <PickUser setValue={setReceiver} />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <CustomInput

                                    color="info"
                                    error={assetCountError}
                                    labelText="Amount *"
                                    id="assetCount"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        type: "text",
                                        required: true,
                                        onChange: function (e) {
                                            setAssetCount(e.target.value)
                                            var re = /^\d+$/;
                                            //required check
                                            setAssetCountError(e.target.value != "" || re.test(e.target.value) ? false : true);
                                        }, value: assetCount, disabled: props.item.tokenType === "NFT" ? true : false
                                    }}
                                />

                            </GridItem>

                        </GridContainer>


                        {loading && <LinearProgress />}
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                        <Button color="info" size="lg" type={"submit"} onClick={(e) => {
                            e.preventDefault()
                            if (true) {
                                handleOpen()
                            }

                        }} disabled={loading}>
                            Transfer</Button>
                    </CardFooter>
                </form>
            </Card>

        </div>

    );
}

export default TransferComponent