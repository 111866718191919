/* eslint-disable */ 
/* eslint-disable */ 
import React from "react";
// react plugin for creating charts
import { makeStyles } from "@material-ui/core/styles";

import DateRange from "@material-ui/icons/DateRange";

import { CardMedia } from '@material-ui/core';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardToken from "components/Card/CardToken.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { format } from 'timeago.js';

const useStyles = makeStyles(styles);

export default function Ticket(props) {
    const classes = useStyles();
    return (
        <Card>
            <GridContainer>
                <GridItem xs={12} sm={6} md={4} lg={4} > <Card>
                    <CardToken color="grey">
                        <div type="ticket" class="widget --flex-column" style={{ maxHeight: 345 }}>
                            <div class="top --flex-column"
                                style={{ backgroundColor: props.item.backgroundColor, color: props.item.textColor }}>
                                <div class="bandname -bold">{props.eventName != "" ? props.eventName : "Event Name"}</div>
                                <div class="tourname">{props.edition != "" ? props.edition : "Edition or Series"}</div>
                                <CardMedia style={{ height: 140 }} image={props.image} />
                                <GridContainer  >
                                    <GridItem xs={8} sm={8} md={8} lg={8}>
                                        <div class="date">{props.eventDate ? new Date(props.eventDate).toDateString() : "Date"}</div>
                                        <div class="date">{props.eventDate ? new Date(props.eventDate).toLocaleTimeString() : "Time"}</div>
                                        <div class="location -bold">{props.location != "" ? props.location : "Location"}</div>
                                    </GridItem>
                                    <GridItem xs={4} sm={4} md={4} lg={4}>
                                        <div class="label">Price</div>
                                        <div class="cost -bold">${props.price}</div>
                                    </GridItem>
                                </GridContainer>
                            </div>
                            <div class="rip" style={{ backgroundColor: props.item.backgroundColor, color: props.item.textColor }}></div>
                            <div class="bottom --flex-row-j!sb" style={{ backgroundColor: props.item.backgroundColor, color: props.item.textColor }}>
                                {props.niftronId}
                            </div>
                        </div >
                    </CardToken> <CardFooter stats>
                        <div className={classes.stats}>
                            <DateRange />
                            {format(props.createdAt)}

                        </div>
                    </CardFooter>
                </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={8} lg={8} >
                    <Card>
                    {props.item.toString()}


                    </Card>
                </GridItem>
            </GridContainer>



        </Card>


    );
}
