/* eslint-disable */ 
import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// import AddAlert from "@material-ui/icons/AddAlert";
// import Snackbar from "components/Snackbar/Snackbar.js";
// import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import sha256 from "sha256"
import Grid from '@material-ui/core/Grid';
// import Email from "@material-ui/icons/Email";
// import Typography from '@material-ui/core/Typography';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dropzone from 'react-dropzone';
import { Link } from "react-router-dom";
import { getUserSession, decryptSecret } from "services/UserManagement"
import PickMultipleUser from "views/Token/Transfer/PickMultipleUser"
import PickMultipleApprovers from "views/Token/Transfer/PickMultipleApprovers"

import LinearProgress from '@material-ui/core/LinearProgress';
import { useSnackbar } from 'notistack';
import { Transfer } from "services/TransferManagement"
import { BuildTransferBadgeNFTXDR, signXDR } from "services/XDRBuilder"
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { Keypair } from "stellar-sdk"
import DataInputFieldGenerator from "components/DataInputFieldGenerator/DataInputFieldGenerator";
import Delete from '@material-ui/icons/Delete';
import IPFS from 'ipfs';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(styles);

function TransferBadgeComponent(props) {
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    setTimeout(function () {
        setCardAnimation("");
    }, 400);
    const [user, setUser] = useState(getUserSession())

    const [transferType, setTransferType] = useState("0");
    const [sender, setSender] = useState(user.publicKey);
    const [receivers, setReceivers] = useState([]);
    const [approvers, setApprovers] = useState([]);

    const [assetCount, setAssetCount] = useState(props.item.tokenType === "NFT" ? "1" : "");

    const [loading, setLoading] = useState(false);
    const [loadingMessage, setloadingMessage] = useState("please remain patient");
    //Confirm 
    const [secret, setSecret] = useState("");

    //Confirm error
    const [secretError, SecretKeyError] = useState(false);
    const [fileError, setFileError] = useState(false)

    //hover and show
    const [hoverPassword, setHoverPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [linkStyle, setLinkStyle] = useState({ cursor: 'pointer' });
    //Errors 
    const [receiversError, setReceiversError] = useState(false);

    const [assetCountError, setAssetCountError] = useState(false);
    const [open, setOpen] = React.useState(false);

    const [fieldContentArray, setFieldContentArray] = useState([])
    const [fieldContent, setFieldContent] = useState({})
    const [showInput, setShowInput] = useState(false)

    const [transferStatesArray, setTransferStatesArray] = useState([])
    const [transferStates, setTransferStates] = useState({})

    const classes = useStyles();

    const { ...rest } = props;
    const { enqueueSnackbar } = useSnackbar();
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    const transferToken = async (event) => {
        event.preventDefault()
        var re = /^\d+$/;
        //required check
        setAssetCountError(assetCount === "" || !re.test(assetCount) ? true : false);
        setReceiversError(receivers.length === 0 ? true : false);
        // }
        if (assetCount != "" && receivers
            && receivers.length > 0) {
            setLoading(true)
            setOpen(false);


            let secretKey = "";
            if (user.authType !== "2") {
                secretKey = decryptSecret(user.encryptedSecret, sha256(secret))
            }
            if (user.authType === "2") {
                secretKey = secret.toUpperCase()
            }
            if (!secretKey || secretKey == "" || secretKey == null || secretKey == undefined) {
                enqueueSnackbar("Invalid Credential", { variant: "warning" });
            } else {
                const keypair = Keypair.fromSecret(secretKey);
                const approversList = approvers.length > 0 ? approvers.map(item => item.publicKey) : []


                for (let i = 0, len = receivers.length; i < len; i++) {
                    addLoadingmessage(receivers[i].alias, "Building Transfer XDR", true)

                    let xdr;
                    let rejectXdr;
                    // let fundXdr;
                    try {

                        // //console.log(receiver)
                        const { xdrs } = await BuildTransferBadgeNFTXDR(
                            sender,
                            receivers[i].publicKey,
                            props.item.assetIssuer,
                            props.item.assetCode,
                            assetCount,
                            approversList,
                            // minTime,
                            // maxTime
                        )
                        addLoadingmessage(receivers[i].alias, "Signing XDR", true)


                        // let expireXdr;

                        if (xdrs != null && xdrs.length > 0) {
                            await Promise.all(
                                xdrs.map(async (item, index, array) => {
                                    xdrs[index].xdr = await signXDR(item.xdr, keypair.secret());
                                })
                            );
                            // fundXdr = xdrs[0]?.xdr
                            xdr = xdrs[0]?.xdr
                            rejectXdr = xdrs[1]?.xdr
                            // cancelXdr = xdrs[2]?.xdr
                            // expireXdr = xdrs[3]?.xdr
                        }



                    } catch (e) {

                        addLoadingmessage(receivers[i].alias,
                            "Failed: Insufficient Fund", false)
                    }


                    //the sender and approvers
                    let signers = [
                        { publicKey: sender, status: "ACCEPTED" },
                        // { publicKey: receiver.publicKey, status: "PENDING" },
                    ]

                    if (approvers.length > 0) {
                        approvers.map((approver, index, array) => {
                            signers.push({ publicKey: approver.publicKey, status: "PENDING" })
                        })

                    }

                    addLoadingmessage(receivers[i].alias, "Adding Data to IPFS", true)

                    const ipfsHash = await AddToIPFS()
                    addLoadingmessage(receivers[i].alias, "Submitting Transfer Request to Niftron", true)
                    const response = await Transfer({
                        transferType,
                        sender,
                        receiver: receivers[i].publicKey,
                        assetCode: props.item.assetCode,
                        assetIssuer: props.item.assetIssuer,
                        assetCount,
                        tokenName: props.item.tokenName,
                        previewUrl: props.item.previewUrl,
                        xdr,
                        rejectXdr,
                        // expireXdr,
                        signers,
                        ipfsHash: ipfsHash ? ipfsHash : ""
                    })
                    switch (response) {
                        case 200:
                            addLoadingmessage(receivers[i].alias,
                                "Successfully Submitted", false)
                                ; break;
                        case 201: addLoadingmessage(receivers[i].alias,
                            "User Not Found", false); break;
                        case 202: addLoadingmessage(receivers[i].alias,
                            "Token Not Found", false); break;
                        case 203: addLoadingmessage(receivers[i].alias,
                            "Insufficient Fund in Account", false); break;
                        case 400: addLoadingmessage(receivers[i].alias,
                            "Failed", false); break;
                        case null: addLoadingmessage(receivers[i].alias,
                            "Failed", false);;
                    }

                }

            }
            let message = "Issued successfully"
            message = receivers.length > 1 ? (approvers.length == 0 ? "Badges issued successfully" : "Badges submitted for approval")
                : (approvers.length == 0 ? "Badge issued successfully" : "Badge submitted for approval")
            enqueueSnackbar(message, { variant: "success" });
            setLoading(false)
        }

    };
    const addData = (fieldName, fieldValue) => {
        let data = fieldContent
        data[fieldName] = fieldValue
        setFieldContent(data)

        var array = []
        for (var property in data) {
            if (data.hasOwnProperty(property)) {
                array.push({ name: property, value: data[property] })
            }
        }
        setFieldContentArray(array)
    }

    const removeData = (fieldName) => {
        let data = fieldContent
        delete data[fieldName]
        setFieldContent(data)
        var array = []
        for (var property in data) {
            if (data.hasOwnProperty(property)) {
                array.push({ name: property, value: data[property] })
            }
        }
        setFieldContentArray(array)
    }

    const confirmAction = (e) => {
        e.preventDefault()
        var re = /^\d+$/;
        setAssetCountError(assetCount === "" || !re.test(assetCount) ? true : false);
        setReceiversError(receivers.length === 0 ? true : false);

        if (assetCount != "" && receivers.length > 0) {
            handleOpen()
        }
    }

    const AddToIPFS = async () => {
        const stringToUse = JSON.stringify(fieldContent)
        const node = await IPFS.create({ repo: String(Math.random() + Date.now()) })
        //console.log('IPFS node is ready')
        const { id, agentVersion, protocolVersion } = await node.id()
        //console.log(id, agentVersion, protocolVersion)
        for await (const { cid } of node.add(stringToUse)) {
            return cid.toString()
        }
        return null
    }

    const addLoadingmessage = (receiver, message, loading) => {
        let data = transferStates
        data[receiver] = { message, loading }
        setTransferStates(data)

        var array = []
        for (var property in data) {
            if (data.hasOwnProperty(property)) {
                array.push({
                    receiver: property,
                    message: data[property].message,
                    loading: data[property].loading,
                })
            }
        }
        setTransferStatesArray(array)
    }

    return (

        <div className={classes.container}>

            <Dialog
                maxWidth="sm"
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                scroll={"body"}
            >
                <DialogTitle id="responsive-dialog-title">{"Please Confirm Action"}</DialogTitle>
                <DialogContent dividers>
                    <form style={loading ? {
                        filter: "blur(1px)",
                        "-webkit-filter": "blur(1px)"
                    } : null} onSubmit={transferToken}>
                        <CustomInput
                            error={secretError}
                            labelText={user.authType === "2" ? "Secret Key *" : "Password *"}
                            id="tokenName"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: !showPassword ? "password" : "text",
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {hoverPassword &&
                                            <Icon style={linkStyle} onMouseEnter={() => { setHoverPassword(true) }} onMouseLeave={() => { setHoverPassword(false) }}
                                                className={classes.inputIconsColor} onClick={() => {
                                                    setShowPassword(!showPassword)

                                                }}>
                                                {showPassword ? "visibilityoff" : "visibility"}</Icon>
                                        }

                                        {!hoverPassword &&
                                            <Icon style={linkStyle} className={classes.inputIconsColor}
                                                onMouseEnter={() => { setHoverPassword(true) }} onMouseLeave={() => { setHoverPassword(false) }}>
                                                lock</Icon>
                                        }
                                    </InputAdornment>
                                ), required: true,
                                onChange: function (e) {
                                    setSecret(e.target.value)
                                    SecretKeyError(e.target.value === "" ? true : false)
                                }, value: secret
                            }}
                        />
                        {user.authType === "2" && <>
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justify="center"
                            >

                                <Grid item xs={3}>
                                    or                  </Grid>

                            </Grid>

                            <Dropzone onDrop={acceptedFiles => {
                                const reader = new FileReader()
                                reader.addEventListener("load", async () => {
                                    // setFile(reader.result)
                                    setSecret(JSON.parse(atob(reader.result)).secretKey)
                                });
                                reader.readAsText(acceptedFiles[0])
                            }}
                                multiple={false} accept={[".niftron"]}>
                                {({ getRootProps, getInputProps }) => (
                                    <Link >
                                        <section className={"container"}>
                                            <div {...getRootProps({ className: fileError ? 'dropzone2Error' : 'dropzone2' })}>
                                                <input {...getInputProps()} />
                                                <p>Drop or select your niftron credential file</p>

                                            </div>
                                        </section>
                                    </Link>
                                )}
                            </Dropzone></>
                        }
                        <Button color={"info"} onClick={transferToken}
                            type={"submit"} disabled={loading}>Confirm</Button>
                    </form>
                </DialogContent>
            </Dialog>
            <Card >
                <form className={classes.form} onSubmit={confirmAction}>
                    <CardHeader color="info" className={classes.cardHeader}>
                        <h3>Issue Badge</h3>
                    </CardHeader>
                    <CardBody>
                        <div style={loading ? {
                            filter: "blur(1px)",
                            "-webkit-filter": "blur(1px)"
                        } : null}><GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={12}>
                                    <PickMultipleUser setValue={setReceivers} receivers={receivers} />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <PickMultipleApprovers setValue={setApprovers} receivers={approvers} />
                                </GridItem> <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput

                                        color="info"
                                        error={assetCountError}
                                        labelText="Amount For Each Receiver *"
                                        id="assetCount"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            required: true,
                                            onChange: function (e) {
                                                setAssetCount(e.target.value)
                                                var re = /^\d+$/;
                                                //required check
                                                setAssetCountError(e.target.value === "" && !re.test(e.target.value) ? true : false);
                                            }, value: assetCount, disabled: props.item.tokenType === "NFT" ? true : false
                                        }}
                                    />

                                </GridItem>

                            </GridContainer>
                            <GridContainer justify="center">
                                {fieldContentArray.map((item, key) => (
                                    <GridItem xs={12} sm={12} md={8} lg={8} key={key} >
                                        <CustomInput
                                            labelText={item.name}
                                            id={item.name}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                type: "text",
                                                endAdornment: (
                                                    <InputAdornment position="end" onClick={() => {
                                                        removeData(item.name)
                                                    }}>
                                                        <Link>  <Delete className={classes.inputIconsColor} /></Link>

                                                    </InputAdornment>
                                                ),
                                                onChange: function (e) {
                                                    addData(item.name, e.target.value)
                                                }, value: item.value, disabled: true
                                            }}
                                        />
                                        {/* <Button onClick={() => {
                                            removeData(item.name)
                                          }}>X</Button> */}
                                    </GridItem>
                                ))}
                                <GridItem xs={12} sm={12} md={8} lg={8}>
                                    {showInput &&
                                        <DataInputFieldGenerator addData={addData} setShowInput={setShowInput}
                                        />
                                    }
                                    <Grid container className={classes.root} spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container justify="center" spacing={2}>
                                                <Grid item>
                                                    {!showInput && <Button
                                                        // disabled={activeStep === 0}
                                                        onClick={() => {
                                                            setShowInput(true)
                                                        }}
                                                        className={classes.backButton}
                                                    > Add Custom Data</Button>}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </GridItem>

                            </GridContainer>
                        </div>

                        {/* {transferStatesArray.map((item, key) => {
                            return (
                                <div key={key}>
                                    {item.receiver}: {item.message}
                                    {item.loading && <CircularProgress />}
                                </div>
                            )
                        })} */}

                        <div className={classes.root}>
                            <Grid container spacing={1}>
                                {transferStatesArray.map((item, key) => {
                                    return (
                                        <Grid container item xs={12} spacing={3} key={key}>
                                            <Grid item xs={4}>
                                                <Paper className={classes.paperName}>{item.receiver}: </Paper>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Paper className={classes.paperValue}> {item.message}{item.loading && <LinearProgress />}</Paper>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </div>
                        {/* {loading && <LinearProgress />} */}
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                        <Button color="info" size="lg" type={"submit"} onClick={confirmAction} disabled={loading || assetCountError || receiversError}>
                            Issue</Button>
                    </CardFooter>
                </form>
            </Card>

        </div>

    );
}

export default TransferBadgeComponent