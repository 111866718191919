/* eslint-disable */ 
/* eslint-disable */ 
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DateRange from "@material-ui/icons/DateRange";
import { CardMedia } from '@material-ui/core';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardToken from "components/Card/CardToken.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { css } from "@emotion/core";
import PulseLoader from "react-spinners/PulseLoader";

const useStyles = makeStyles(styles);

export default function DummyToken(props) {
    const classes = useStyles();
    return (
        <Card>

            <GridContainer style={{ minHeight: 500 }}>
                {/* <GridItem xs={12} sm={6} md={4} lg={3} className={"loadingCard"}>
                    <Card className={"loadingCard"}>
                        <CardToken color="grey" className={"loadingCard"}>
                            <div className="container loadingCard">
                                <CardMedia style={{ height: 345, maxHeight: 345 }} image={""} />
                                <div class="overlayLoading">
                                    <div class="textBlack">
                                        <div className="sweet-loading">
                                            <PulseLoader
                                                color={"#008CBA"}
                                                loading={true}
                                            />
                                        </div>
                                        {"Loading Preview"}</div>
                                </div>
                            </div>
                        </CardToken>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <DateRange />
                                {"**** ***** ***"}
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem> */}
                <GridItem xs={12} sm={12} md={12} lg={12} className={"loadingCard"}>
                    <Card className={"loadingCard"}>
                        <CardBody className={"loadingCard"}>
                            <div className="container loadingCard">
                                <div style={{ height: 345, maxHeight: 345 }} ></div>
                                <div class="overlayLoading">
                                    <div class="textBlack">
                                        <div className="sweet-loading">
                                            <PulseLoader
                                                color={"#008CBA"}
                                                loading={true}
                                            />
                                        </div>{props.loadingText}</div>
                                </div>
                            </div>
                        </CardBody>
                        <CardFooter stats>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </Card>
    );
}
