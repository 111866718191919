/* eslint-disable */ 
import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Typography from '@material-ui/core/Typography';
import Dropzone from 'react-dropzone';
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import AddAlert from "@material-ui/icons/AddAlert";
// import Snackbar from "components/Snackbar/Snackbar.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { loginWithSecret } from "services/UserManagement.js";
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux'

import styles from "assets/jss/material-kit-react/views/loginPage.js";


const useStyles = makeStyles(styles);

function SecretLogin(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 400);
  const loadingMessage = useSelector(state => state.LoadingMessage)

  const [previousRoute, setPreviousRoute] = useState(props.previousRoute);
  const [secretKey, setSecretKey] = useState("");
  const [file, setFile] = useState(null)

  const [loading, setLoading] = useState(false);
  //hover and show
  const [hoverPassword, setHoverPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [linkStyle, setLinkStyle] = useState({ cursor: 'pointer' });

  //Errors 
  const [secretKeyError, setSecretKeyError] = useState(false);
  const [fileError, setFileError] = useState(false)


  const classes = useStyles();

  const { ...rest } = props;
  const { enqueueSnackbar } = useSnackbar();

  const Login = async (event) => {
    event.preventDefault()

    //required check
    setSecretKeyError(secretKey === "" ? true : false);
    if (secretKey != "") {
      setLoading(true)

      const response = await loginWithSecret(secretKey)
      switch (response) {
        case 200: enqueueSnackbar("Logged In Successfully", { variant: "success" });
          props.history.push(previousRoute); break;
        case 201: enqueueSnackbar("Account Not Found", { variant: "warning" });
          setSecretKeyError(true); break;
        case 202: enqueueSnackbar("Secret Key is Invalid", { variant: "error" });
          setSecretKeyError(true); break;
        case null: enqueueSnackbar("Secret Key is Invalid", { variant: "error" });
          setSecretKeyError(true);
      }
      setLoading(false)
    }

  };

  return (

    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={5}>
          <Card className={classes[cardAnimaton]}>
            <form className={classes.form} onSubmit={Login} >
              <CardHeader color="info" className={classes.cardHeader}>
                <h3>Secret Key Login</h3>
              </CardHeader>
              <CardBody>
                <div style={loading ? {
                  filter: "blur(1px)",
                  "-webkit-filter": "blur(1px)"
                } : null}>{props.warning != "" && <SnackbarContent
                  message={
                    props.warning
                  }
                  icon={AddAlert}
                  color="warning"

                />}

                  <CustomInput
                    //info
                    error={secretKeyError}
                    labelText="Secret Key *"
                    id="secretKey"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: !showPassword ? "password" : "text",
                      endAdornment: (
                        <InputAdornment position="end">
                          {hoverPassword &&
                            <Icon style={linkStyle} onMouseEnter={() => { setHoverPassword(true) }} onMouseLeave={() => { setHoverPassword(false) }}
                              className={classes.inputIconsColor} onClick={() => {
                                setShowPassword(!showPassword)

                              }}>
                              {showPassword ? "visibilityoff" : "visibility"}</Icon>
                          }

                          {!hoverPassword &&
                            <Icon style={linkStyle} className={classes.inputIconsColor}
                              onMouseEnter={() => { setHoverPassword(true) }} onMouseLeave={() => { setHoverPassword(false) }}>
                              lock</Icon>
                          }

                        </InputAdornment>
                      ),
                      autoComplete: "off",
                      onChange: function (e) {
                        setSecretKey(e.target.value)
                        setSecretKeyError(e.target.value === "" ? true : false);
                      }, value: secretKey
                    }}
                  />
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                  >

                    <Grid item xs={3}>
                      or                  </Grid>

                  </Grid>

                  <Dropzone onDrop={acceptedFiles => {
                    const reader = new FileReader()
                    reader.addEventListener("load", async () => {
                      // setFile(reader.result)
                      setSecretKey(JSON.parse(atob(reader.result)).secretKey)
                      setSecretKeyError(false);
                    });
                    reader.readAsText(acceptedFiles[0])
                  }}
                    multiple={false} accept={[".niftron"]}>
                    {({ getRootProps, getInputProps }) => (
                      <Link >
                        <section className={"container"}>
                          <div {...getRootProps({ className: fileError ? 'dropzone2Error' : 'dropzone2' })}>
                            <input {...getInputProps()} />
                            <p>Drop or select your niftron credential file</p>

                          </div>
                        </section>
                      </Link>
                    )}
                  </Dropzone>
                </div>
                {loading && <LinearProgress />}
                {loading && <p className={classes.divider}>{loadingMessage}</p>}

              </CardBody>
              <CardFooter className={classes.cardFooter}>
                <Button color="info" size="lg" type={"submit"} onClick={Login}
                  disabled={loading || secretKeyError}>
                  Login</Button>
              </CardFooter>
              <Typography gutterBottom variant="body2" color="textSecondary" align={"center"}>
                Not a niftroner?<Button simple color="info" onClick={(e) => {
                  e.preventDefault()
                  props.setStep('ACCOUNT')
                }}>Register</Button>
              </Typography>
              <Typography gutterBottom variant="body2" color="textSecondary" align={"center"}>
                Not a high privacy niftroner?<Button simple color="info" onClick={(e) => {
                  e.preventDefault()
                  props.setStep('LOGIN')
                }}>Classical Login</Button>
              </Typography>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>

  );
}

export default SecretLogin