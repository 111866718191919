/* eslint-disable */ 
import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import KYC from "views/AuthPage/Components/KYC.js";
import GridContainer from "components/Grid/GridContainer.js";

const useStyles = makeStyles(styles);

function KycAlone(props) {

  const [step, setStep] = useState('KYC');
  const [previousRoute, setPreviousRoute] = useState('/');
  const classes = useStyles();

  const { ...rest } = props;

  useEffect(() => {
    document.body.style.overflow = "scroll";


  }, []);
  return (
    <div>
      <div
        className={classes.container}>
        <GridContainer justify="center">
          <img height="50px" style={{ margin: "50px 0px 50px 0px" }}
            src="sidebar.png" onClick={(e) => {
              props.history.push("/")
            }} /></GridContainer>
        {
          step === 'KYC' && (
            <KYC setStep={setStep} history={props.history} previousRoute={previousRoute} kycAlone={true} />
          )
        }
       
      </div > <Footer />
    </div >
  );
}

export default KycAlone